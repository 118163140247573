import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import {
  Stepper,
  Group,
  Button,
  TextInput,
  PasswordInput,
  NumberInput,
  Select,
  MultiSelect,
  FileInput,
  Code,
} from "@mantine/core";
import axios from "axios";
import { MoonLoader } from "react-spinners";
import { auth } from "../../utils/Firebase/firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { AnimatePresence, motion } from "framer-motion";
import "../../pages/LoginSignup/LoginSignup.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUp = (props) => {
  const nav = useNavigate();

  const [userType, setUserType] = useState(1);
  const [active, setActive] = useState(0); // Step management
  const [subactive, setSubactive] = useState(0); // Sub-step for phone verification
  const [otpLoading, setOtpLoading] = useState(0); // Loading state for OTP

  const classes = ["Class IX", "Class X", "Class XI", "Class XII"];
  const exams = ["Jee Main", "Jee Advanced", "NEET", "KVPY"];

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      firstname: "",
      lastname: "",
      school: "",
      class: "",
      exam: "",
      phone: "",
      otp: "",
      profileImage: null, // Profile image state
      idCard: null, // ID card state
    },
    validate: (values) => {
      if (active === 0) {
        return {
          email: /^\S+@\S+$/.test(values.email) ? null : "Invalid email",
          password:
            values.password.length < 6
              ? "Password must include at least 6 characters"
              : null,
          confirmPassword:
            values.confirmPassword !== values.password
              ? "Passwords did not match"
              : null,
        };
      }

      if (active === 1) {
        return {
          firstname:
            values.firstname.trim().length < 2
              ? "Name must include at least 2 characters"
              : null,
          lastname:
            values.lastname.trim().length < 2
              ? "Name must include at least 2 characters"
              : null,
          school:
            !values.school && userType === 2
              ? "Select associated school"
              : null,
          class: !values.class && userType === 1 ? "Select your class" : null,
          exam:
            values.exam.length === 0 && userType === 1
              ? "Select at least one target exam"
              : null,
        };
      }

      if (active === 2 && subactive === 0) {
        return {
          phone:
            values.phone.toString().substring(0, 2) !== "91"
              ? "Invalid country code"
              : values.phone.toString().substring(2).length !== 10
              ? "Invalid phone number"
              : null,
        };
      }

      if (active === 2 && subactive === 1) {
        return {
          otp: values.otp.length !== 6 ? "Incomplete OTP" : null,
        };
      }

      return {};
    },
  });

  // Submit function to handle signup with files and form data
  const submitSignup = async (event) => {
    event.preventDefault();

    const data = form.values;

    // Create FormData object to handle both text and file inputs
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("firstname", data.firstname);
    formData.append("lastname", data.lastname);
    formData.append("school", data.school);
    formData.append("class", data.class);
    formData.append("exam", data.exam);
    formData.append("phone", data.phone);
    formData.append("profileImage", data.profileImage); // Profile image file
    formData.append("idCard", data.idCard); // ID card file

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/register/email`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set to handle file uploads
          },
        }
      );

      if (res.data.redirect === true) {
        accCreated();
      } else if (res.data.message) {
        throw new Error(res.data.message);
      }
    } catch (err) {
      console.log(err.message);
      toast(err.message);
    }
  };

  const accCreated = () => {
    toast("Account Created Successfully");
    props.setLoader(true);
    if (props.user.current.code === 1) {
      nav("/student");
    } else {
      nav("/educator");
    }
  };

  const sendOTP = async () => {
    let phone = form.values.phone;
    let appVerifier = window.recaptchaVerifier;
    const formatPh = "+" + phone;

    setOtpLoading(1);
    if (!appVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log(response);
          },
          "expired-callback": () => {},
        },
        auth
      );
      appVerifier = window.recaptchaVerifier;
    }

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log("OTP sent successfully!");
        setSubactive(1);
        setOtpLoading(0);
      })
      .catch((error) => {
        console.log(error);
        setOtpLoading(0);
      });
  };

  const verifyOTP = () => {
    let otp = form.values.otp;
    setOtpLoading(1);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setActive((prev) => prev + 1);
        setOtpLoading(0);
      })
      .catch((err) => {
        console.log(err);
        form.setFieldError("otp", "Incorrect OTP");
        setOtpLoading(0);
      });
  };

  const nextStep = async () => {
    if (form.validate().hasErrors) {
      setActive((prev) => prev);
    } else if (active === 2 && subactive === 0) setActive((prev) => prev);
    else if (active === 2 && subactive === 1) {
      verifyOTP();
    } else {
      setActive((prev) => prev + 1);
    }

    if (form.validate().hasErrors) {
      return setSubactive((prev) => prev);
    } else if (active === 2 && subactive === 0) {
      sendOTP();
    }
  };

  const prevStep = () => {
    setActive((current) =>
      current === 2 && subactive === 1 ? current : current - 1
    );
    setSubactive(0);
  };

  function funcBtn(e) {
    if (active === 2) submitSignup(e);
    else nextStep(e);
  }

  return (
    <>
      <motion.div
        initial={false}
        animate={{
          x:
            props.page === 1
              ? window.innerWidth < 1024
                ? "-100vw"
                : "-50vw"
              : 0,
        }}
        transition={{ duration: 0.7 }}
        className="signup"
      >
        <div id="recaptcha-container"></div>
        <AnimatePresence>
          {userType !== 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="signup-content"
            >
              <span className="signup-heading">Create Account</span>
              <span className="signup-subheading">
                Sign up using email and enter details.
              </span>

              <Stepper active={active} onStepClick={setActive} progressIcon={false}>
                {/* Step 1: Profile Settings */}
                <Stepper.Step label="Step 1" description="Profile settings">
                  <TextInput
                    mt="md"
                    withAsterisk
                    label="Email"
                    placeholder="user@email.com"
                    {...form.getInputProps("email")}
                  />
                  <PasswordInput
                    mt="md"
                    withAsterisk
                    label="Password"
                    placeholder="Password"
                    {...form.getInputProps("password")}
                  />
                  <PasswordInput
                    mt="md"
                    withAsterisk
                    label="Confirm password"
                    placeholder="Confirm password"
                    {...form.getInputProps("confirmPassword")}
                  />
                </Stepper.Step>

                {/* Step 2: Personal Information + File Uploads */}
                <Stepper.Step label="Step 2" description="Personal information">
                  <div className="name-input">
                    <TextInput
                      mt="md"
                      withAsterisk
                      label="First Name"
                      placeholder="First Name"
                      {...form.getInputProps("firstname")}
                    />
                    <TextInput
                      mt="md"
                      withAsterisk
                      label="Last Name"
                      placeholder="Last Name"
                      {...form.getInputProps("lastname")}
                    />
                  </div>
                  {userType === 2 && (
                    <Select
                      mt="md"
                      withAsterisk
                      label="School"
                      searchable
                      placeholder="Select associated school"
                      nothingFound="No schools found"
                      data={["School A", "School B", "School C"]}
                      {...form.getInputProps("school")}
                    />
                  )}
                  {userType === 1 && (
                    <div>
                      <Select
                        mt="md"
                        withAsterisk
                        label="Class"
                        placeholder="Select your class"
                        data={classes}
                        {...form.getInputProps("class")}
                      />
                      <MultiSelect
                        mt="md"
                        withAsterisk
                        label="Exam"
                        placeholder="What are you preparing for?"
                        data={exams}
                        {...form.getInputProps("exam")}
                      />
                    </div>
                  )}

                  {/* File Upload for Profile Image */}
                  <FileInput
                    mt="md"
                    label="Profile Image"
                    placeholder="Upload your profile image"
                    accept="image/png,image/jpeg"
                    {...form.getInputProps("profileImage")}
                  />

                  {/* File Upload for ID Card */}
                  <FileInput
                    mt="md"
                    label="ID Card"
                    placeholder="Upload your ID card"
                    accept="image/png,image/jpeg"
                    {...form.getInputProps("idCard")}
                  />
                </Stepper.Step>

                {/* Step 3: Mobile Number Verification */}
                <Stepper.Step label="Step 3" description="Mobile Number">
                  <Stepper
                    className="inner-stepper"
                    active={subactive}
                    onStepClick={setSubactive}
                  >
                    <Stepper.Step>
                      <NumberInput
                        mb="xl"
                        mt="md"
                        withAsterisk
                        placeholder="91 0000 000 000"
                        hideControls={true}
                        label="Mobile Number"
                        {...form.getInputProps("phone")}
                      />
                    </Stepper.Step>
                  </Stepper>
                </Stepper.Step>
                <Stepper.Completed>
                  Completed! Form values:
                  <Code block mt="xl">
                    {JSON.stringify(form.values, null, 2)}
                  </Code>
                </Stepper.Completed>
              </Stepper>

              <Group position="right" mt="sm">
                {otpLoading && (
                  <MoonLoader color="var(--primary)" size="25px" />
                )}
                {active !== 0 && (
                  <Button
                    className="signup-prev"
                    variant="default"
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                )}
                {active !== 3 && (
                  <Button
                    className="signup-next"
                    onClick={funcBtn}
                  >
                    {active < 2 ? "Next step" : "Sign Up"}
                  </Button>
                )}
              </Group>
              <div className="switch">
                <span>Already have an account?</span>
                <Link className="switch-button" to="/login">
                  Log in
                </Link>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </>
  );
};

export default SignUp;
