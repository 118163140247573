import React, { useEffect } from "react";
import { useState } from "react";
import LogIn from "../../components/LoginSignup/LogIn";
import Illustration from "../../components/LoginSignup/Illustration";
import SignUp from "../../components/LoginSignup/SignUp";
import { useLocation } from "react-router-dom";

const Landing = (props) => {
  const [page, setPage] = useState(1);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/signup") {
      setPage(2);
    } else {
      setPage(1);
    }
  }, [location]);

  return (
    <div className="landing">
      <SignUp {...props} page={page} />
      <Illustration page={page} />
      <LogIn {...props} page={page} />
    </div>
  );
};

export default Landing;
