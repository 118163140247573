import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import StudentDashboard from "./pages/StudentDashboard/StudentDashboard";
import LoginSignup from "./pages/LoginSignup/LoginSignup";
import LandingPage from "./pages/LandingPage";
import CoursePage from "./pages/CoursePage";
import EditCourse from "./pages/EditCourse/EditCourse";
import EducatorDashboard from "./pages/EducatorDashboard/EducatorDashboard";
import Resources from "./pages/Resources";
import StudentCalendarPage from "./pages/StudentCalendarPage";
import EducatorCalendarPage from "./pages/EducatorCalendarPage";
import EditQuizPage from "./pages/EditQuiz/EditQuizPage";
import NewQuizPage from "./pages/EditQuiz/NewQuizPage";
import Live from "./pages/Live";
import Liveclass from "./pages/Liveclass";
import Quiz from "./pages/Quiz/Quiz";
import PreQuiz from "./pages/Quiz/PreQuiz";
import PostQuiz from "./pages/Quiz/PostQuiz";
import ReviewQuiz from "./pages/Quiz/ReviewQuiz";
import CoursePreview from "./pages/CoursePreview";
import MyContents from "./pages/MyContents";
import SearchLanding from "./components/LandingPage/SearchLanding";
import Loader from "./loader";
import MyItems from "./pages/MyContents";
import Page404 from "./pages/Page404/Page404";
import InDev from "./pages/InDev/InDev";
import ResourceView from "./pages/ResourceView";
import { ToastContainer, toast } from "react-toastify";
import ProfilePage from "./pages/ProfilePage";
import EditProfile from "./pages/EditProfile";
import CreateCourse from "./pages/CreateCourse";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { getUser } from "./utils/getUser";
import { useEffect, useRef, useState } from "react";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Refund from "./pages/Refund";
import ProblemBlog from "./pages/ProblemBlog";
import CareerDetail from "./pages/CareerPagePage/CareerDetail";
import CareerList from "./pages/CareerPagePage/CareerList";
import PsychoQuiz from "./pages/PsychometricQuiz/PsychoQuiz";
import ProgressForm from "./components/ProgressForm/ProgressForm";
import axios from "axios";
import { getCookie } from "./utils/apiCaller";
// import StudentDashboardTest, { StudentDashBoardTest } from "./pages/StudentDashboard/StudentDashBoardTest";

function App() {
  const user = useRef(false);
  const [loader, setLoader] = useState(true);

  const fetchData = async () => {
    try {
      const result = await getUser();
      user.current = result;
      setLoader(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [loader]);

  useEffect(() => {
    console.log("USER::::::  ", user.current);
  }, [user]);

  const doCheckout = async () => {
    const userData = user.current;

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/create-order/`,
        {},
        {
          withCredentials: true,
          withXSRFToken: true,
          xsrfCookieName: "csrftoken",
          xsrfHeaderName: "X-CSRFToken",
        },
        { headers: { "X-CSRFToken": getCookie("csrftoken") } }
      );
      const orderData = resp.data?.order;

      if (!resp.data.success || !orderData) {
        throw new Error("Error in creating order");
      }

      const options = {
        key: process.env.RAZORPAY_KEY_ID,
        amount: orderData.amount.toString(),
        currency: orderData.currency,
        name: "Matrigyan Course",
        description: "Purchase Course",
        order_id: orderData.id,
        callback_url: "https://api.matrigyan.com/razorpay-callback",
        prefill: {
          name: userData.student.full_name,
          email: userData.user.email,
          contact: userData.student.phone,
        },
        theme: {
          color: "#3399cc",
        },
        handler: function (response) {
          console.log(response);
          const data = {
            order_id: orderData.id,
            payment_id: response.razorpay_payment_id,
            signature: response.razorpay_signature,
          };

          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/verify-payment/`,
              data,
              {
                withCredentials: true,
                withXSRFToken: true,
                xsrfCookieName: "csrftoken",
                xsrfHeaderName: "X-CSRFToken",
              },
              { headers: { "X-CSRFToken": getCookie("csrftoken") } }
            )
            .then((res) => {
              if (res.data.success) {
                toast("Payment Successful!");
                fetchData();
              } else {
                toast("Payment Failed");
              }
            })
            .catch((err) => {
              console.log(err);
              toast("Payment Failed");
            });
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        toast(`Payment Failed: ${response.error.description}`);
      });
      rzp1.open();
    } catch (error) {
      console.log(error);
      toast("Error in creating order");
    }
  };

  return (
    <Router>
      <div>
        <ToastContainer></ToastContainer>

        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/refund" element={<Refund />} />
          <Route exact path="/problem/blog" element={<ProblemBlog />} />
          <Route exact path="/progress/1" element={<ProgressForm />} />
          <Route path="/quiz/:userId" element={<PsychoQuiz user={user} />} />

          {loader ? (
            <Route exact path="*" element={<Loader />} />
          ) : (
            <>
              <Route
                path="/login"
                element={<LoginSignup user={user} setLoader={setLoader} />}
              />
              <Route
                path="/signup"
                element={<LoginSignup user={user} setLoader={setLoader} />}
              />
              {/* <Route
                path="/student/profile"
                element={<ProfilePage user={user} />}
              /> */}
              <Route
                path="/profile"
                element={<ProfilePage user={user} setLoader={setLoader} />}
              />
              <Route
                path="/student"
                element={
                  <StudentDashboard
                    user={user}
                    setLoader={setLoader}
                    doCheckout={doCheckout}
                  />
                }
              />
              <Route path="/courses" element={<CoursePage user={user} />} />
              <Route
                path="/educator/contents"
                element={<MyContents user={user} />}
              />
              <Route path="/resources" element={<Resources user={user} />} />
              <Route
                path="/educator"
                element={
                  <EducatorDashboard user={user} setLoader={setLoader} />
                }
              />
              <Route
                path="/course/:ID/edit"
                element={<EditCourse user={user} />}
              />
              <Route
                path="/course/add"
                element={<CreateCourse user={user} />}
              />
              <Route
                path="/calendar"
                element={
                  user.current.code === 1 ? (
                    <StudentCalendarPage user={user} />
                  ) : (
                    <EducatorCalendarPage user={user} />
                  )
                }
              />
              <Route
                path="/quiz/:ID/edit"
                element={<EditQuizPage user={user} />}
              />
              <Route
                path="/quiz/new"
                element={
                  <NewQuizPage user={user} userID={user.current?.user?.id} />
                }
              />
              <Route path="/live/:ID" element={<InDev />} />
              <Route path="/quiz/:ID" element={<Quiz />} />
              <Route path="/quiz/:ID/start" element={<PreQuiz />} />
              <Route path="/quiz/:ID/end" element={<PostQuiz />} />
              <Route path="/quiz/:ID/review" element={<ReviewQuiz />} />
              <Route
                path="/course/:id"
                element={<CoursePreview user={user} />}
              />
              <Route
                path="/resourceview/:ID"
                element={<ResourceView user={user} />}
              />
              <Route path="/resourceview" element={<ResourceView />} />

              <Route path="/edit/profile" element={<EditProfile />} />

              <Route path="/not-found" element={<Page404 />} />
              <Route path="/developing" element={<InDev />} />
              <Route path="/*" element={<Page404 />} />
              {/* <Route path="/student-dashboard-test" element={<StudentDashBoardTest />} /> */}
              <Route path="/careerlist" element={<CareerList user={user} />} />
              <Route
                path="/career/:careerName"
                element={<CareerDetail user={user} />}
              />
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
