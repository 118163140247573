import React from "react";
import productOffering from "../../assets/LandingPage/assets/img/productOffering/productofferings.svg";

function ProductOffering() {
  return (
    <div className="max-w-7xl m-auto pt-48 pb-24">
      <div className="text-black text-3xl sm:text-5xl text-center font-bold">
        We provide everything you need
      </div>
      <img className="rounded-3xl my-16" src={productOffering} alt="" />
    </div>
  );
}

export default ProductOffering;