// src/components/TableOfContents.js

import React from 'react';

const ContentsPP = () => {
  return (
    <div className="top-4 p-4 bg-gray-100 rounded-lg shadow-md mb-8">
      <h2 className="text-xl font-bold mb-4">Table of Contents</h2>
      <ul className="list-disc pl-4 space-y-2">
        <li>
          <a href="#information-collection" className="font-bold text-blue hover:underline-offset-1">
            Information Collection
          </a>
        </li>
        <li>
          <a href="#use" className="font-bold text-blue hover:underline-offset-1">
            Use
          </a>
        </li>
        <li>
          <a href="#cookies" className="font-bold text-blue hover:underline-offset-1">
            Cookies
          </a>
        </li>
        <li>
          <a href="#sharing" className="font-bold text-blue hover:underline-offset-1">
            Sharing
          </a>
        </li>
        <li>
          <a href="#links-to-other-sites" className="font-bold text-blue hover:underline-offset-1">
            Links to Other Sites
          </a>
        </li>
        <li>
          <a href="#security-precautions" className="font-bold text-blue hover:underline-offset-1">
            Security Precautions
          </a>
        </li>
        <li>
          <a href="#advertisements" className="font-bold text-blue hover:underline-offset-1">
            Advertisements
          </a>
        </li>
        <li>
          <a href="#data-retention" className="font-bold text-blue hover:underline-offset-1">
            Data Retentions
          </a>
        </li>
        <li>
          <a href="#consents" className="font-bold text-blue hover:underline-offset-1">
            Consents
          </a>
        </li>
        <li>
          <a href="#changes-to-privacy-policy" className="font-bold text-blue hover:underline-offset-1">
            Changes to this Privacy Policy
          </a>
        </li>
        <li>
          <a href="#queries" className="font-bold text-blue hover:underline-offset-1">
            Queries
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ContentsPP;
