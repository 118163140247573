// Import necessary assets
import gallery_1 from "../../../assets/LandingPage/assets/img/gallery/gallery_1.jpg";
import gallery_2 from "../../../assets/LandingPage/assets/img/gallery/gallery_2.jpg";
import gallery_3 from "../../../assets/LandingPage/assets/img/gallery/gallery_3.jpg";
import gallery_4 from "../../../assets/LandingPage/assets/img/gallery/gallery_4.jpg";
import gallery_5 from "../../../assets/LandingPage/assets/img/gallery/gallery_5.jpg";
import gallery_6 from "../../../assets/LandingPage/assets/img/gallery/gallery_6.jpg";
import gallery_7 from "../../../assets/LandingPage/assets/img/gallery/gallery_7.jpg";
import gallery_8 from "../../../assets/LandingPage/assets/img/gallery/gallery_8.jpg";
import gallery_9 from "../../../assets/LandingPage/assets/img/gallery/gallery_9.jpg";
import gallery_10 from "../../../assets/LandingPage/assets/img/gallery/gallery_10.jpg";

// Import Swiper core and required modules
import { Navigation, EffectCards, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-cards";

const Gallery = () => {
  const gallery_images = [
    gallery_1,
    gallery_2,
    gallery_3,
    gallery_4,
    gallery_5,
    gallery_6,
    gallery_7,
    gallery_8,
    gallery_9,
    gallery_10,
  ];
  return (
    <div className="my-32 m-auto">
      <div className="text-5xl md:text-6xl text-center font-bold m-auto">
        Our Gallery
      </div>
      <div className="hidden sm:block max-w-6xl mx-auto py-8 px-4">
        {" "}
        {/* Container with increased max width */}
        <Swiper
          effect={"cards"}
          grabCursor={true}
          mousewheel={true}
          modules={[EffectCards, Navigation, Autoplay]}
          loop={true}
          autoplay={{
            delay: 2000,
          }}
          // navigation
          className="mySwiper"
        >
          {gallery_images.map((photo, index) => (
            <SwiperSlide key={index}>
              <img
                src={photo}
                alt=""
                className="w-full sm:h-[500px] rounded-lg object-cover"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="w-[90vw] mx-auto py-8 sm:hidden">
        {" "}
        {/* Container with increased max width */}
        <Swiper
          mousewheel={true}
          modules={[Navigation, Autoplay]}
          loop={true}
          autoplay={{
            delay: 2000,
          }}
          // navigation
          className="swiper swiper-initialized swiper-horizontal mySwiper"
        >
          {gallery_images.map((photo, index) => (
            <SwiperSlide key={index}>
              <img
                src={photo}
                alt=""
                className="w-full sm:h-[500px] rounded-lg object-cover"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Gallery;
