import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../components/LandingPage/Navbar";
import Home from "../components/LandingPage/Home";
import Category from "../components/LandingPage/Category";
import Info from "../components/LandingPage/Info";
import Instructors from "../components/LandingPage/Instructors";
import Courses from "../components/LandingPage/Courses";
// import '../components/LandingPage/assets/css/bootstrap.min.css';
// import '../components/LandingPage/assets/css/owl.carousel.min.css';
// import '../components/LandingPage/assets/css/owl.theme.default.min.css';
// import '../components/LandingPage/assets/css/style.css';
import "font-awesome/css/font-awesome.min.css";

// import AOS from 'aos';
// import 'aos/dist/aos.css';
import Point from "../components/LandingPage/Point";
import Footer from "../components/LandingPage/Footer";
import SearchLanding from "../components/LandingPage/SearchLanding";
import Loader from "../loader";
import Motto from "../components/LandingPage/Motto";
import Gallery from "../components/LandingPage/Gallery/Gallery";
import Careerpath from "../components/LandingPage/Careerpath";
import AmbassadorCarousel from "../components/LandingPage/AmbassadorCarousel/AmbassadorCarousel";
import ProbOffering from "../components/LandingPage/ProbOffering";
import Timeline from "../components/LandingPage/Timeline";
import ProductOffering from "../components/LandingPage/ProductOffering";
import Psychometric from "../components/LandingPage/Psychometric";
import { MagicCardDemo } from "../components/PricingCard/Card";
const LandingPage = () => {
  // useEffect(() => {
  //   AOS.init();
  // }, []);
  const [loader, setLoader] = useState(true)
  useEffect(()=>{

      console.log(loader,"loader")
      setLoader(false);


  },[])
  // import
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("Courses");
  const [searchPage, setSearchPage] = useState(0);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleCategory = (event) => {
    setCategory(event.target.value);
  };

  return (
    <>
    {loader?<Loader></Loader>:""}


    <div className="main-wrapper bg-white md:px-0 px-4" id="landingpage">
      <Navbar
        searchPage={searchPage}
        setSearchPage={setSearchPage}
        search={search}
        category={category}
        handleSearch={handleSearch}
        handleCategory={handleCategory}
      ></Navbar>
      {!searchPage && (
        <div>
          <Home
            search={search}
            handleSearch={handleSearch}
            category={category}
            handleCategory={handleCategory}
            searchPage={searchPage}
            setSearchPage={setSearchPage}
          ></Home>
          <ProbOffering />
          {/* <Category></Category> */}
          <Motto />
          <ProductOffering />
          <Timeline />
          <Psychometric />
          {/* <Courses></Courses> */}
          {/* <Info></Info> */}
          <Careerpath />
          <MagicCardDemo/>
          <AmbassadorCarousel />
          {/* <Instructors></Instructors> */}
          {/* <Point></Point> */}
          <Gallery />
          <Footer></Footer>
        </div>
      )}
      {searchPage && (
        <SearchLanding
          search={search}
          handleSearch={handleSearch}
          category={category}
          handleCategory={handleCategory}
        />
      )}
    </div>
    </>
  );
};

export default LandingPage;
