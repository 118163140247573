import ShineBorder from "../../magicComp/components/magicui/shine-border";
import { useTheme } from "next-themes";

export function ShineBorderDemo({ children }) {
  const { theme } = useTheme();

  return (


      <ShineBorder
      className="relative flex h-[500px] w-full flex-col items-center justify-center overflow-hidden rounded-lg border  md:shadow-xl"
      color={["#A07CFE", "#FE8FB5", "#FFBE7B"]}
    >
      <div className="z-10 w-full max-w-lg mx-auto px-4 py-8">
        {children}
      </div>
    </ShineBorder>


  );
}
