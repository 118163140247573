import React from "react";
import timeline from "../../assets/LandingPage/assets/img/Timeline/timeline.svg";

function Timeline() {
  return (
    <div className="max-w-7xl m-auto">
      <div className="text-black text-3xl sm:text-5xl text-center font-bold">
        Councelling stages
      </div>
      <img className="rounded-3xl my-16" src={timeline} alt="" />
    </div>
  );
}

export default Timeline;
