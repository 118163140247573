import { BorderBeam } from "../../magicComp/components/magicui/border-beam";

export function BorderBeamDemo({ children }) {
  return (
    <div className="relative flex h-[500px] w-full flex-col items-center justify-center overflow-hidden rounded-lg md:shadow-xl">
      <div className="z-10 w-full max-w-lg mx-auto px-4 py-8">
        {children}
      </div>
      <BorderBeam size={250} duration={12} delay={9} />
    </div>
  );
}
