import React from "react";
import Navbar from "../components/LandingPage/Navbar";
import "font-awesome/css/font-awesome.min.css";
import Footer from "../components/LandingPage/Footer";

const Refund = () => {
  return (
    <>
      <div className="main-wrapper" id="landingpage">
        <Navbar></Navbar>

        <div className="container-refund md:w-[50%] w-[90%] mx-auto bg-white mt-36 mb-12 p-8 rounded-lg shadow-2xl text-justify">
          <h3 className="text-4xl font-semibold text-center mb-8">Cancellation & Refund</h3>

          <p className="text-black mb-6">
            The MatriGyan renders services including but not limited to
            counseling, assessment tests etc, which act as a bonafide guide to
            the user and the services are rendered as soon as availed, therefore
            there is no provision for refund of fee paid by the User/s. The
            user/s is therefore deemed to have understood and accepted that once
            the fee is paid to the MatriGyan as per the services availed, there
            shall be no refund of the said Fee.
          </p>
        </div>
        <Footer></Footer>
      </div>
    </>
  );
};

export default Refund;
