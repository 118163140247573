import Meteors from "../../magicComp/components/magicui/meteors";

export function MeteorDemo({children}) {
  return (
    <div className="relative flex h-[500px] w-full flex-col items-center justify-center overflow-hidden rounded-lg  md:shadow-xl">
      <Meteors number={30} />
      <div className="z-10 w-full max-w-lg mx-auto px-4 py-8">
        {children}
      </div>
    </div>
  );
}
