import React from "react";
import Navbar from "../components/LandingPage/Navbar";
// import "font-awesome/css/font-awesome.min.css";
import Footer from "../components/LandingPage/Footer";
import ContentsTC from "../components/TermsAndConditions/ContentsTC";

const Terms = () => {
  return (
    <div className="" id="landingpage">
      <Navbar />

      <div className="container-tandc md:w-[50%] w-[90%] mx-auto bg-white mt-36 mb-12 p-8 rounded-lg shadow-2xl text-justify">
        <h3 className="text-4xl font-semibold text-center mb-8">
          Terms & Conditions
        </h3>
        <ContentsTC />
        <h3 className="text-2xl font-semibold mt-6 mb-4" id="overview">Overview</h3>
        <p className="text-black mb-6">
          According to the Information Technology Act of 2000 and any applicable
          Rules made thereunder, this document is an electronic record. This
          electronic record is created by a computer system; therefore, physical
          or digital signatures are not required. This document has been
          published in accordance with Rule 3 (1) of the Information Technology
          (Intermediaries guidelines) Rules, 2011, which mandates the
          publication of a website’s terms and conditions, privacy statement,
          and user agreement. The website here refers to
          <a
            href="https://matrigyan.com"
            className="text-blue underline ml-1 font-bold"
          >
            https://matrigyan.com
          </a>
          . A technology-enabled, multidimensional career assessment website
          called{" "}
          <a href="https://matrigyan.com" className="text-blue ml-1 font-bold">
            https://matrigyan.com
          </a>{" "}
          aids users in making the best career decisions for themselves. You
          should read the current agreement (hereinafter referred to as the
          EULA) and accept its terms before using our services or products.
          Before using the website, the User shall be deemed to have read and
          properly accepted the terms of the EULA. These terms could
          occasionally change or be updated, but only at our discretion. You
          agree to be bound by the terms and conditions outlined in the EULA by
          using our website. You are required and accountable for routinely
          reviewing the EULA to keep yourself informed of any updates/amendments
          made to the EULA from time to time by us. You will be deemed to have
          accepted and agreed to the said changes if you continue to access the
          website after such changes to the EULA.
        </p>

        <h3 className="text-2xl font-semibold mt-6 mb-4" id="references">
          References of Terms Used
        </h3>
        <p className="text-black mb-6">
          The following definitions apply to the terms used in the current EULA:
          All references to the Website, its owner, directors, employees, and
          people and/or entities connected to the Owner are collectively
          referred to as “MatriGyan” throughout the entirety of this EULA.
          Throughout this entire EULA, the terms “USER,” “YOU,” and “YOUR” refer
          to any individual, legal entity, society, group of people, or other
          human beings who access the website or register there to use one of
          the website’s many services. Throughout the entire EULA, the phrase
          “OUR WEBSITE” or “WEBSITE” refers to the website{" "}
          <a
            href="https://matrigyan.com"
            className="text-blue underline ml-1 font-bold"
          >
            https://matrigyan.com
          </a>
          . All references to “OUR PRODUCTS/SERVICES” in this EULA are to goods
          or services provided by the Website or its affiliated companies,
          excluding any advertisements that may be shown on the Website.
          Throughout this entire EULA, the term “APPROPRIATE GOVERNMENT” shall
          mean the Central/State Government or the applicable local authorities.
          Both the singular and plural forms of the terms shall be equally
          applicable to the terms and conditions herein. Any pronoun must always
          include both the corresponding masculine and feminine forms, depending
          on the context. The Privacy Policy and Disclaimer of the Website shall
          also include the terms as defined herein.
        </p>

        <h3 className="text-2xl font-semibold mt-6 mb-4" id="requirements">Requirements</h3>
        <p className="text-black mb-6">
          All individuals, entities, businesses, partnerships, and other types
          of entities, as the case may be, are eligible to use the website.
          However, in accordance with the Indian Contract Act, 1872, any
          registration on the website must be made by a person who is legally
          able to enter into binding contracts. Users who fall under the
          definition of “incompetent to contract” under the Indian Contract Act,
          1872, such as minors, unsolved insolvents, etc., are not permitted to
          use the website unless they are under the legal or constructive
          guardianship of those who are legally qualified to enter into
          contracts on behalf of the relevant minor users. When you register for
          an account on the website, you guarantee that the data you enter is
          accurate, that you are legally able to accept this agreement, and that
          you have the power to bind yourself or your business entity to it. You
          further affirm that you will use the website and browse it in
          accordance with the EULA. If it comes to our attention that you are
          using the website in violation of the EULA or if it is determined that
          you are doing so, MatriGyan reserves the right to terminate your
          registration and refuse to grant you access to the Website, at its
          sole discretion and for any reason.
        </p>

        <h3 className="text-2xl font-semibold mt-6 mb-4" id="registration">
          Obligacy Of Registration
        </h3>
        <p className="text-black mb-6">
          You are in charge of protecting the privacy of your User ID and
          Password. You are accountable for every action taken using your User
          ID and Password. You consent to, among other things: providing true,
          accurate, current, and complete information about yourself as prompted
          by the MatriGyan registration form; maintaining and promptly updating
          the Registration Data to keep it true, accurate, current, and complete
          at all times; immediately notifying us of any unauthorized use of your
          password or account or any other security breach; and making sure you
          log out of your account at the conclusion of each session. MatriGyan
          reserves the right to permanently suspend, terminate, or restrict
          access to your registration with the website if you provide any
          information that is untrue, inaccurate, not current, or incomplete, or
          if MatriGyan has good faith grounds to suspect that such information
          is any of the above. Your failure to abide by this Clause releases
          MatriGyan from any liability for any loss or damage that results.
        </p>

        <h3 className="text-2xl font-semibold mt-6 mb-4" id="#electronic-interaction">
          E-Registration and Electronic Interaction
        </h3>
        <p className="text-black mb-6">
          You acknowledge and agree that electronic records are used to
          communicate with MatriGyan when you use the Website, send emails, or
          send other data, information, or communication to MatriGyan. We are
          allowed to contact you electronically with your consent. You will hear
          from us by email, through announcements on the website, or through
          other sources.
        </p>

        <h3 className="text-2xl font-semibold mt-6 mb-4" id="services">
          Synopsis of Services
        </h3>
        <p className="text-black mb-6">
          MatriGyan is an ecosystem for career planning and development for
          students that is supported by technology. In order to reduce human
          bias in the decision-making process for careers, we make use of
          technology, research, machine learning, and algorithms. MatriGyan
          provides a distinctive method of career counseling, mentoring, and
          guidance that includes but is not limited to psychometric tests,
          interactive career-focused activities, career progression or tracking
          mechanisms, etc., as may be occasionally provided for the assistance
          and guidance of user(s). By agreeing to the terms of this agreement,
          you acknowledge that it is your responsibility and solely your choice
          to use the services provided by MatriGyan, free from any undue
          influence from MatriGyan. Additionally, you agree to make sure that
          you use the services in accordance with the terms and conditions of
          the EULA.
        </p>

        <h3 className="text-2xl font-semibold mt-6 mb-4" id="payments">Payments</h3>
        <p className="text-black mb-6">
          According to the pricing structure on the website, MatriGyan will
          charge for the services rendered. You agree to pay for the services
          before using them if you use the ones that are mentioned there as
          being subject to payment. You further agree not to bring up any
          disagreement regarding the fee for the services in any way because you
          agreed to it voluntarily and free from coercion. You further represent
          that you have read and accepted the Terms & Conditions of the Payment
          that are presented to you when you proceed to pay for the paid
          services. MatriGyan accepts payments from users through third-party
          payment processors. Orders that are canceled or executed incorrectly
          due to payment problems are not the fault of MatriGyan. Although
          MatriGyan takes every precaution when working with third-party payment
          providers, it has no control over their systems, processes,
          technology, or workflows and is therefore not liable for any errors
          made by these companies.
          <br />
          Users have no right or voice to influence the fee that MatriGyan will
          charge users, which will be decided solely by MatriGyan.
        </p>

        <h3 className="text-2xl font-semibold mt-6 mb-4" id="taxation">Taxation</h3>
        <p className="text-black mb-6">
          Unless already included in the price offered, you agree to pay all
          applicable taxes, charges, cess, etc. levied on the services being
          provided to you and announced by the Central/State Govt from time to
          time.
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
