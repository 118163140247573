import ProbOfferPallete from "./ProbOfferPallete";
import { Link } from "react-router-dom";
import probGraph from "../../assets/LandingPage/assets/img/probOffering/bargraph.svg";

export default function ProbOffering() {
  return (
    <div className="w-full max-w-7xl mx-auto my-32 shadow-2xl rounded-3xl overflow-hidden bg-[#f2f6ff]" id="ProblemOffering">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="space-y-4 p-8 md:p-12 bg-[#f2f6ff]">
          <h2 className="text-4xl font-bold">Problem</h2>
          <p className="text-muted-foreground font-bold">
            A staggering 93% of PCM students in India are aware of only seven
            conventional career options—like engineering, medicine, and
            law—despite there being over 800 possible career paths. This lack of
            awareness leaves students unsure about their future.
          </p>
          <img src={probGraph} alt="" />
          <Link
  to="/problem/blog"
  className="inline-flex h-12 items-center justify-center rounded-md bg-blue-500 text-white px-8 text-sm font-medium shadow hover:bg-green-400 transition duration-300"
>
  Learn More
</Link>

        </div>
        <div className="space-y-4 p-8 md:p-12 pl-0 flex flex-col justify-center items-start center bg-white">
          <h2 className="text-4xl md:text-6xl lg:text-8xl font-bold">Offerings</h2>
          <p className="text-md font-bold">
            We offer a completely integrated process of educational counselling
            and career analysis best fit for each student. Our acknowledgement
            that everyone is unique helps us identify the best-tailored paths
            for everyone to follow according to their abilities.
          </p>
          <ProbOfferPallete />

          {/* New Button to Scroll to BuyNow */}
          <a
            href="/#BuyNow"
            className="inline-flex h-12 items-center justify-center rounded-md bg-blue-500 text-white px-8 text-sm font-medium shadow hover:bg-green-400 transition duration-300"
          >
            Buy Now
          </a>
        </div>
      </div>
    </div>
  );
}
