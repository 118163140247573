const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      // Extract image name from the path and remove the './' prefix
      const imageName = item.replace('./', '').split('.')[0];
      images[imageName] = r(item);
    });
    return images;
  };
  
  // Create a context for the images directory
  const images = importAll(require.context('../../../assets/LandingPage/assets/img/ambassadors/', false, /\.(png|jpe?g|svg)$/));
  
  export default images;