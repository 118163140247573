import React from 'react';

export default function CareerPath() {
  const steps = [
    {
      id: 1,
      title: "Post-School Decision Making",
      details: ["After 12th"],
    },
    {
      id: 2,
      title: "College Selection and Admission",
      details: ["College Selection", "Application process"],
    },
    {
      id: 3,
      title: "College Years",
      details: [
        "Year 1: Foundation",
        "Year 2: Core Concepts",
        "Year 3: Specialization",
        "Year 4: Advanced Learning & Job",
      ],
    },
    {
      id: 4,
      title: "Involvement in Societies",
      details: [
        "In-College Societies",
        "Out-of-College Societies",
        "Networking Events",
        "Workshops and Seminars",
        "Open-Source Contributions",
      ],
    },
    {
      id: 5,
      title: "Getting a Job & Interview Preparation",
      details: [
        "Resume Building",
        "Internships",
        "Placement Preparation",
        "Interview Process",
        "Post Interview",
      ],
    },
    {
      id: 6,
      title: "Continuous Learning and Career Development",
      details: ["First Job", "Skill Enhancement", "Career Growth"],
    },
  ];

  return (
    <div className="w-full max-w-4xl mb-96 mx-auto py-12 md:py-16">
      <div className="text-black text-3xl md:text-5xl text-center md:m-5 m-2 my-5 font-bold">
        We are there for you at every step of your career
      </div>
      <div className="relative flex items-center justify-center">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full h-[2px] bg-muted" />
        </div>
        <div className="relative z-10 flex items-center justify-between w-full">
          {steps.map(({ id, title, details }) => (
            <CareerStep key={id} id={id} title={title} details={details} />
          ))}
        </div>
        <div className="absolute inset-0 flex items-center justify-between">
          <div className="h-[2px] w-full bg-primary" />
        </div>
      </div>
    </div>
  );
}

function CareerStep({ id, title, details }) {
  return (
    <div className="group relative flex flex-col items-center">
      <div className="w-8 h-8 md:w-16 md:h-16 bg-primary rounded-full transition-all duration-400 group-hover:scale-125 group-hover:cursor-pointer" />
      <h1 className="absolute top-2 sm:top-4 text-white text-xl font-bold text-primary-foreground">{id}</h1>
      <div className="bg-white absolute top-full mt-6 mx-2 w-48 sm:w-64 rounded-lg bg-background p-4 shadow-lg transition-all duration-400 group-hover:scale-105 group-hover:block group-hover:z-10">
        <div className="flex items-start gap-4">
          <div className="bg-white space-y-1">
            <h4 className="font-bold text-xl">{title}</h4>
            <ul className="list-disc mx-3 font-medium text-[10px] sm:text-sm">
              {details.map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function BriefcaseIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 20V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
      <rect width="20" height="14" x="2" y="6" rx="2" />
    </svg>
  );
}

function CalendarDaysIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8 2v4" />
      <path d="M16 2v4" />
      <rect width="18" height="18" x="3" y="4" rx="2" />
      <path d="M3 10h18" />
      <path d="M8 14h.01" />
      <path d="M12 14h.01" />
      <path d="M16 14h.01" />
      <path d="M8 18h.01" />
      <path d="M12 18h.01" />
      <path d="M16 18h.01" />
    </svg>
  );
}

function RocketIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z" />
      <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z" />
      <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0" />
      <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5" />
    </svg>
  );
}
