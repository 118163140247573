import { Link } from "react-router-dom";
import data from "./data.json";
import careerImage from "./Mesa de trabajo 1.png"; // Import your local image

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../loader";
import Sidebar from "../../components/StudentDashboard/Sidebar";

const CareerList = (props) => {
  const [activeCategory, setActiveCategory] = useState(null);

  const handleClick = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
  };

  const navigate = useNavigate();
  const [Data, setData] = useState({});
  const mounted = useRef(false);
  const [loader, setLoader] = useState(true);
  /*
  const [courses,setCourses] = useState([]);
  const getCourses = async ()=>{
    try{
      const res = await axios.get('http://127.0.0.1:8000/get-courses/')
      const data = res.data
      console.log(res.data);
      setCourses(data.data);
    } catch(error){
      console.log(error);
    }
  }
  useEffect(()=>{
    getCourses();
  }, [])
*/

  useEffect(() => {
    setLoader(false);
  }, []);
  useEffect(() => {
    mounted.current = true;
    const config = {
      withCredentials: true,
      // headers: {
      //   "X-CSRFToken": getCookie("csrftoken"),
      // },
    };
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/student-dashboard-data`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.success) {
          if (res.data.code === 2) navigate("/educator");
          else {
            toast("Please Login first.");
            navigate("/login");
          }
        }

        if (mounted.current) {
          setData(res.data);
          console.log(Data);
        }
      })
      .catch((err) => {
        const notify = () => toast(err.message);
        notify();
        navigate("/");
      });
    return () => (mounted.current = false);
  }, []);

  var comp = 0;
  if (Object.keys(Data).length)
    comp = Data.enrolled_courses.length - Data.on_courses.length;

  return (
    <>
      <ToastContainer></ToastContainer>
      {loader ? <Loader></Loader> : ""}
      <Sidebar user={props.user} tab={0} />
      <div className="min-h-screen max-w-7xl m-auto p-10">
        <div className="flex flex-col lg:flex-row items-center mb-12 justify-center">
          <div className="lg:w-1/2 mb-8 lg:mb-0 pr-4">
            <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-8 text-left">
              Explore Career Paths
            </h1>
            <p className="text-gray-600 text-lg text-left">
              Discover your next exciting career opportunity with us. We offer a
              range of roles designed to help you achieve your professional
              goals and reach new heights. Take the next step in your career
              journey and explore the possibilities that await you—your future
              starts here. Find it today.
            </p>
            <Link
              to="/student"
              className="text-lg text-white bg-blue-500 px-6 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              Back to dashboard
            </Link>
          </div>

          {/* Right side: Image */}
          <div className="lg:w-1/2">
            <img
              src={careerImage}
              alt="Career Opportunities"
              className="w-[50%] h-[50%] m-auto"
            />
          </div>
        </div>

        {/* Career Cards Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {data.map((item, index) => (
            <div
              key={index}
              className="bg-white p-8 rounded-xl h-full flex flex-col justify-between shadow-xl"
            >
              <div>
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                  {item.category}
                </h2>
                <p className="text-gray-600 mb-6">{item.description}</p>
              </div>
              <div>
                <button
                  className={`w-full py-3 px-6 rounded-lg text-white font-bold focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 ease-in-out ${
                    activeCategory === item.category
                      ? "bg-blue-600 hover:bg-blue-700"
                      : "bg-blue-500 hover:bg-blue-600"
                  }`}
                  onClick={() => handleClick(item.category)}
                >
                  {activeCategory === item.category
                    ? "Hide Careers"
                    : "Explore Careers"}
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Modal Popup */}
        {activeCategory && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-11/12 max-w-4xl h-auto overflow-y-auto relative shadow-2xl">
              <button
                className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                onClick={() => setActiveCategory(null)}
              >
                ✖
              </button>
              <h2 className="text-2xl font-bold mb-4 text-center">
                {activeCategory}
              </h2>

              {/* Updated Career Cards Section */}
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {data
                  .find((item) => item.category === activeCategory)
                  .careers.map((career, idx) => (
                    <div
                      key={idx}
                      className="bg-gray-100 p-4 rounded-md shadow-md flex flex-col justify-between"
                    >
                      <h3 className="text-lg font-semibold text-center mb-2">
                        {career}
                      </h3>
                      <Link
                        to={`/career/${career}`}
                        className="text-white bg-blue-500 hover:bg-blue-600 py-2 px-4 rounded-md transition-all duration-300 ease-in-out text-center font-bold"
                      >
                        Know More
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CareerList;
