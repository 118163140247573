// import React from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../loader";
import Sidebar from "../../components/StudentDashboard/Sidebar";

const CareerDetail = (props) => {
  const { careerName } = useParams();
  const navigate = useNavigate();
  const [Data, setData] = useState({});
  const mounted = useRef(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(false);
  }, []);
  useEffect(() => {
    mounted.current = true;
    const config = {
      withCredentials: true,
    };
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/student-dashboard-data`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.success) {
          if (res.data.code === 2) navigate("/educator");
          else {
            toast("Please Login first.");
            navigate("/login");
          }
        }

        if (mounted.current) {
          setData(res.data);
          console.log(Data);
        }
      })
      .catch((err) => {
        const notify = () => toast(err.message);
        notify();
        navigate("/");
      });
    return () => (mounted.current = false);
  }, []);

  var comp = 0;
  if (Object.keys(Data).length)
    comp = Data.enrolled_courses.length - Data.on_courses.length;

  return (
    <>
      <ToastContainer></ToastContainer>
      {loader ? <Loader></Loader> : ""}
      <Sidebar user={props.user} tab={0} />
      <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-10">
        <h1 className="text-5xl font-extrabold text-gray-800 mb-6">
          {careerName}
        </h1>
        <p className="text-2xl text-gray-600 mb-4">Coming Soon...</p>
        <Link
          to="/careerlist"
          className="text-lg text-white bg-blue-500 px-6 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
        >
          Back to Career List
        </Link>
      </div>
    </>
  );
};

export default CareerDetail;
