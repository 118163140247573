import { useState } from "react";
import data from "./QuestionsData.json";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import ParticlesDemo from "../../components/QuestionPallete/Qpallete";
import { ShineBorderDemo } from "../../components/QuestionPallete/ShinyBorder";
import { BorderBeamDemo } from "../../components/QuestionPallete/BorderBeam";
import { MeteorDemo } from "../../components/QuestionPallete/Meteor";
import confetti from "canvas-confetti";
import React, { useEffect, useRef } from "react";
import axios from "axios";
import Modal from 'react-modal';

Modal.setAppElement('#root'); // For accessibility

function PsychoQuiz(props) {
  const { careerName } = useParams();
  const navigate = useNavigate();
  const [Data, setData] = useState({});
  const mounted = useRef(false);
  const [loader, setLoader] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  const handleClick = () => {
    const end = Date.now() + 3 * 1000; // 3 seconds
    const colors = ["#a786ff", "#fd8bbc", "#eca184", "#f8deb1"];

    const frame = () => {
      if (Date.now() > end) return;

      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        startVelocity: 60,
        origin: { x: 0, y: 0.5 },
        colors: colors,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        startVelocity: 60,
        origin: { x: 1, y: 0.5 },
        colors: colors,
      });

      requestAnimationFrame(frame);
    };

    frame();
  };

  const profilePic =
    props.user?.current?.code === 1
      ? `${process.env.REACT_APP_BACKEND_URL}${props.user?.current?.student?.profile_pic}`
      : props.user?.current?.code === 2
      ? `${process.env.REACT_APP_BACKEND_URL}${props.user?.current?.educator?.profile_pic}`
      : null;

  const userName = props.user?.current?.student?.full_name;
  const todayDate = new Date().toLocaleDateString();

  useEffect(() => {
    setLoader(false);
  }, []);

  useEffect(() => {
    mounted.current = true;
    const config = {
      withCredentials: true,
    };
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/student-dashboard-data`, config)
      .then((res) => {
        if (!res.data.success) {
          if (res.data.code === 2) navigate("/educator");
          else {
            toast("Please Login first.");
            navigate("/login");
          }
        }

        if (mounted.current) {
          setData(res.data);
        }
      })
      .catch((err) => {
        toast(err.message);
        navigate("/");
      });

    return () => (mounted.current = false);
  }, []);

  const sections = data.questionnaire.sections;
  const [currentSection, setCurrentSection] = useState(0);
  const [answers, setAnswers] = useState({});
  const [result, setResult] = useState({});

  const section = sections[currentSection];

 // Function to get CSRF token
function getCsrfToken() {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, 10) === 'csrftoken=') {
          cookieValue = decodeURIComponent(cookie.substring(10));
          break;
        }
      }
    }
    return cookieValue;
  }

  // Function to save the result
  const saveResult = async (_result) => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCsrfToken(),  // Include CSRF token if necessary
        },
      };
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/save-result/`, { result: _result }, config);
      if (response.data.success) {
        toast("Result saved successfully!");
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.message);
    }
  };

  // Call the saveResult function after generating the results
  const generateResult = () => {
    const _result = {};
    Object.keys(answers).forEach((key) => {
      const [sectionIdx, questionIdx] = key.split("-").map(Number);
      const answer = answers[key];
      const question = sections[sectionIdx].questions[questionIdx];
      const score = question.scoring[answer];

      Object.keys(score).forEach((key) => {
        if (!_result[key]) {
          _result[key] = 0;
        }
        _result[key] += score[key];
      });
    });

    // Save the result to the backend
    saveResult(_result);

    // Trigger confetti and modal if necessary
    handleClick();
    setIsModalOpen(true); // Open the modal
  };


  // Modal close function
  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/student");
  };

  // Helper function to render questions in different components based on index
  const renderQuestionComponent = (q, qIdx) => {
    switch (qIdx % 4) {
      case 0:
        return (
          <ParticlesDemo key={qIdx}>
            <h3 className="text-lg font-medium mb-2">{q.question_text}</h3>
            <form>
              {q.options.map((answer, aIdx) => {
                const answerKey = answer[0];
                return (
                  <label key={aIdx} className="block mb-2">
                    <input
                      type="radio"
                      className="mr-2"
                      name={`question-${currentSection}-${qIdx}`}
                      checked={answers[`${currentSection}-${qIdx}`] === answerKey}
                      onChange={() =>
                        setAnswers((prev) => ({
                          ...prev,
                          [`${currentSection}-${qIdx}`]: answerKey,
                        }))
                      }
                    />
                    {answer.slice(3)}
                  </label>
                );
              })}
            </form>
          </ParticlesDemo>
        );
      case 1:
        return (
          <ShineBorderDemo key={qIdx}>
            <h3 className="text-lg font-medium mb-2">{q.question_text}</h3>
            <form>
              {q.options.map((answer, aIdx) => {
                const answerKey = answer[0];
                return (
                  <label key={aIdx} className="block mb-2">
                    <input
                      type="radio"
                      className="mr-2"
                      name={`question-${currentSection}-${qIdx}`}
                      checked={answers[`${currentSection}-${qIdx}`] === answerKey}
                      onChange={() =>
                        setAnswers((prev) => ({
                          ...prev,
                          [`${currentSection}-${qIdx}`]: answerKey,
                        }))
                      }
                    />
                    {answer.slice(3)}
                  </label>
                );
              })}
            </form>
          </ShineBorderDemo>
        );
      case 2:
        return (
          <MeteorDemo key={qIdx}>
            <h3 className="text-lg font-medium mb-2">{q.question_text}</h3>
            <form>
              {q.options.map((answer, aIdx) => {
                const answerKey = answer[0];
                return (
                  <label key={aIdx} className="block mb-2">
                    <input
                      type="radio"
                      className="mr-2"
                      name={`question-${currentSection}-${qIdx}`}
                      checked={answers[`${currentSection}-${qIdx}`] === answerKey}
                      onChange={() =>
                        setAnswers((prev) => ({
                          ...prev,
                          [`${currentSection}-${qIdx}`]: answerKey,
                        }))
                      }
                    />
                    {answer.slice(3)}
                  </label>
                );
              })}
            </form>
          </MeteorDemo>
        );
      case 3:
        return (
          <BorderBeamDemo key={qIdx}>
            <h3 className="text-lg font-medium mb-2">{q.question_text}</h3>
            <form>
              {q.options.map((answer, aIdx) => {
                const answerKey = answer[0];
                return (
                  <label key={aIdx} className="block mb-2">
                    <input
                      type="radio"
                      className="mr-2"
                      name={`question-${currentSection}-${qIdx}`}
                      checked={answers[`${currentSection}-${qIdx}`] === answerKey}
                      onChange={() =>
                        setAnswers((prev) => ({
                          ...prev,
                          [`${currentSection}-${qIdx}`]: answerKey,
                        }))
                      }
                    />
                    {answer.slice(3)}
                  </label>
                );
              })}
            </form>
          </BorderBeamDemo>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-blue-100 p-6">
      {/* User Info Container */}
      <div className="max-w-3xl mx-auto h-52 bg-white rounded-lg flex items-center justify-between p-6 mb-8 shadow-lg">
        <img src={profilePic} alt="Profile" className="h-24 w-24 rounded-full object-cover" />
        <div className="text-right">
          <h2 className="text-xl font-semibold">{userName}</h2>
          <p className="text-gray-600">{todayDate}</p>
        </div>
      </div>

      <div className="max-w-3xl mx-auto rounded-lg p-8">
        <h1 className="text-3xl font-bold text-center mb-8">Psychometric Test</h1>
        <div className="mb-6 text-center">
          <h2 className="text-xl font-semibold">{section.section_name}</h2>
          <h6 className="text-gray-600">Section {currentSection + 1} / {sections.length}</h6>
        </div>

        <div className="quiz-container mb-8">
          {section.questions.map((q, qIdx) => (
            <div key={qIdx} className="mb-4 bg-white rounded-lg">
              {renderQuestionComponent(q, qIdx)}
            </div>
          ))}
        </div>

        <div className="flex justify-between mt-4">
          {/* Back Button */}
          {currentSection > 0 && (
            <button
              className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 transition duration-200"
              onClick={() => {
                setCurrentSection((curr) => curr - 1);
              }}
            >
              Back
            </button>
          )}

          {/* Continue Button */}
          {currentSection !== sections.length - 1 ? (
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200 ml-auto"
              onClick={() => {
                window.scrollTo(0, 0);
                setCurrentSection((curr) => curr + 1);
              }}
            >
              Continue
            </button>
          ) : (
            <button
              className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-200 ml-auto"
              onClick={generateResult}
            >
              Submit
            </button>
          )}
        </div>

        {/* Modal to display results */}
        <Modal
  isOpen={isModalOpen}
  onRequestClose={closeModal}
  className="fixed top-1/2 left-1/2 w-2/5 bg-white opacity-100 p-8 rounded-lg shadow-xl z-50 transform -translate-x-1/2 -translate-y-1/2"
  overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
>

    <h2 className="text-2xl font-semibold mb-4">Congratulations!</h2>
    <p className="mb-4">You have successfully completed the psychometric test.</p>
    <button
      className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
      onClick={closeModal}
    >
      Go to Dashboard
    </button>

</Modal>

      </div>
    </div>
  );
}

export default PsychoQuiz;
