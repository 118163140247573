import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ProgressForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [formData, setFormData] = useState({
    interests: '',
    profession: '',
    achievement: ''
  });
  const [videoLink, setVideoLink] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();  // For redirecting to the student dashboard

  // Fetch the form submission status and questions on component mount
  useEffect(() => {
    axios.get('http://localhost:8000/progress/1')  // Update the URL if needed
      .then(response => {
        if (response.data.submitted) {
          setSubmitted(true);
          setVideoLink(response.data.video_link);
        } else {
          setQuestions(response.data.questions);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching progress form status:', error);
        setLoading(false);
      });
  }, []);

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('http://localhost:8000/progress/1', formData)
      .then(response => {
        setSubmitted(true);
        setVideoLink(response.data.video_link);
      })
      .catch(error => {
        console.error('Error submitting form:', error);
      });
  };

  // Handle navigation to the student dashboard
  const goToStudentDashboard = () => {
    navigate('/student');
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6">
      {submitted ? (
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-md text-center">
          <h1 className="text-2xl font-semibold text-green-600 mb-4">Thank you for submitting!</h1>
          <p className="text-lg mb-6">Here is a video for you:</p>

          {videoLink && (
            <div className="mb-6">
              <iframe
                className="w-full h-64 rounded-md"
                src={`https://www.youtube.com/embed/${new URL(videoLink).searchParams.get('v')}`}
                title="YouTube video"
                allowFullScreen
              ></iframe>
            </div>
          )}

          <a
            href={videoLink}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition mb-4"
          >
            Watch Video on YouTube
          </a>

          <button
            onClick={goToStudentDashboard}
            className="w-full bg-green-600 text-white py-2 rounded-md font-medium hover:bg-green-700 transition"
          >
            Go to Student Dashboard
          </button>
        </div>
      ) : (
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full">
          <h1 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Progress Form</h1>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-lg font-medium text-gray-700 mb-2">{questions[0]}</label>
              <textarea
                name="interests"
                value={formData.interests}
                onChange={handleInputChange}
                maxLength={300}
                required
                className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your top interests or hobbies..."
              />
            </div>
            <div>
              <label className="block text-lg font-medium text-gray-700 mb-2">{questions[1]}</label>
              <textarea
                name="profession"
                value={formData.profession}
                onChange={handleInputChange}
                maxLength={300}
                required
                className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your desired profession..."
              />
            </div>
            <div>
              <label className="block text-lg font-medium text-gray-700 mb-2">{questions[2]}</label>
              <textarea
                name="achievement"
                value={formData.achievement}
                onChange={handleInputChange}
                maxLength={300}
                required
                className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your personal or academic achievement..."
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-3 rounded-md font-medium hover:bg-blue-700 transition"
            >
              Submit
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ProgressForm;
