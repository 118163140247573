export default function DashboardButtonPallate() {
  return (
    <div className="grid md:grid-cols-4 max-md:grid-rows-1 justify-between">
      <div className="bg-background p-9 m-6 rounded-lg border bg-[#e2e8f0] hover:bg-[#d0e2ff]">
        <div className="flex flex-col h-32 sm:h-40 md:h-48 lg:h-56 rounded-lg">
          <span className="text-left text-lg font-bold">
            Basic Process
          </span>
          <span className="text-md font-medium text-muted-foreground">
            Moving forward, one has to engage in a carefully designed
            step-by-step process to undergo full training to make well-informed
            decisions.
          </span>
        </div>
      </div>
      <div className="bg-background p-9 m-6 rounded-lg border bg-[#e2e8f0] hover:bg-[#d0e2ff]">
        <div className="flex flex-col h-32 sm:h-40 md:h-48 lg:h-56 rounded-lg">
          <span className="text-left text-lg font-bold">
            Onboarding and Materials
          </span>
          <span className="text-md font-medium text-muted-foreground">
            Student onboarding will be done at the beginning, and materials will
            be handed over to them to go through thoroughly.
          </span>
        </div>
      </div>
      <div className="bg-background p-9 m-6 rounded-lg border bg-[#e2e8f0] hover:bg-[#d0e2ff]">
        <div className="flex flex-col h-32 sm:h-40 md:h-48 lg:h-56 rounded-lg">
          <span className="text-left text-lg font-bold">
            Result of Test
          </span>
          <span className="text-md font-medium text-muted-foreground">
            Once the psychometric test is concluded, a counselling session will
            be held and post-result declaration, the findings will be
            considered.
          </span>
        </div>
      </div>
      <div className="bg-background p-9 m-6 rounded-lg border bg-[#e2e8f0] hover:bg-[#d0e2ff]">
        <div className="flex flex-col h-32 sm:h-40 md:h-48 lg:h-56 rounded-lg">
          <span className="text-left text-lg font-bold">
            Career Path
          </span>
          <span className="text-md font-medium text-muted-foreground">
            Data-driven career paths will be formulated, taking into account all
            the inputs received through which students can make informed
            decisions.
          </span>
        </div>
      </div>
    </div>
  );
}
