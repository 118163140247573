import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getUser } from "../../utils/getUser"; // Import the getUser function from the utility file

const Navbar = (props) => {
  const [scroll, setScroll] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  const [user, setUser] = useState(null); // Store user data

  // Fetch user information when Navbar is mounted using the getUser function
  useEffect(() => {
    getUser().then((response) => {
      if (response && response.success && response.user) {
        setIsLoggedIn(true); // User is logged in
        setUser(response.user); // Store user data
      } else {
        setIsLoggedIn(false); // User is not logged in
      }
    });
  }, []);

  return (
    <header className="header">
      <div className="header-fixed">
        <nav
          className={
            scroll
              ? "navbar add-header-bg  header-nav scroll-sticky"
              : "navbar  header-nav scroll-sticky"
          }
        >
          <div className="container">
            <div className="navbar-header">
              <a id="mobile_btn" href="javascript:void(0);">
                <span className="bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </a>
              <Link to="/">
                <a href="/" className="navbar-brand logo">
                  <img
                    src="/logo.png"
                    className=""
                    alt="Logo"
                    style={{ maxHeight: "100px" }}
                  />
                </a>
              </Link>
            </div>

            {props.searchPage ? (
              <div className="banner-content m-0">
                <form className="form border rounded-full">
                  <div className="form-inner p-2">
                    <div className="input-group gap-2 items-center">
                      <FontAwesomeIcon icon={faMagnifyingGlass} color="grey" />
                      <input
                        type="email"
                        className="form-control justify-content-center m-0"
                        placeholder="Search"
                        value={props.search}
                        onChange={props.handleSearch}
                      />
                      <span className="drop-detail m-0">
                        <select
                          className="form-select pl-3 select w-fit"
                          value={props.category}
                          onChange={props.handleCategory}
                        >
                          <option>Courses</option>
                          <option>Educators</option>
                          <option>Materials</option>
                        </select>
                      </span>
                      <button
                        className="w-[44px] h-[44px] btn btn-primary sub-btn p-0"
                        onClick={() => props.setSearchPage(0)}
                      >
                        <FontAwesomeIcon icon={faArrowRight} rotation={180} />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}

            <ul className="nav header-navbar-rht">
              {isLoggedIn ? (
                // Show Dashboard button if user is logged in
                <li className="nav-item">
                  <Link to="/student">
                    <a className="nav-link header-sign">
                      Dashboard
                    </a>
                  </Link>
                </li>
              ) : (
                // Show Login and Signup buttons if user is not logged in
                <>
                  <li className="nav-item">
                    <Link to="/login">
                      <a className="nav-link header-sign" href="login">
                        Login
                      </a>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/signup">
                      <a className="nav-link header-login" href="signup">
                        Signup
                      </a>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
