import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faStar } from "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

const Home = (props) => {
  console.log(props.search, "+++", props.category);
  return (
    <section className="d-flex align-items-center h-fit pt-10 sm:h-[100vh] bg-[#f2f6ff] rounded-3xl mt-32 sm:mt-10">
      <div className="container">
        <div className="flex md:flex-row-reverse flex-col">
          <div className="w-[100%] md:w-[50%]">
            <div className="home-slide-face aos md:w-[100%] w-[90%]" data-aos="fade-up">
              <div className="home-slide-text ">
                <h1>
                  Unlock Your True{" "}
                  <span className="text-[#d0e2ff]">Potential</span> <br />
                  with Data-Driven Career Insights
                </h1>
                <p>
                  A comprehensive career guidance platform that combines
                  <span className="font-bold"> psychometric</span> testing, <span className="font-bold">personalised</span> counselling, and industry
                  trends to deliver tailored, <span className="font-bold">data-driven</span> career roadmaps for
                  long-term success and satisfaction.
                </p>
              </div>
              {/* <div className="banner-content">
                <form className="form" action="course-list.html">
                  <div className="form-inner shadow-lg">
                    <div className="input-group">
                      <FontAwesomeIcon className="fas fa-magnifying-glass search-icon" />
                      <input
                        type="email"
                        className="form-control justify-content-center"
                        placeholder="Search School, Topics, Courses etc"
                        value={props.search}
                        onChange={props.handleSearch}
                      />
                      <span className="drop-detail">
                        <select
                          className="form-select pl-3 select"
                          value={props.category}
                          onChange={props.handleCategory}
                        >
                          <option>Courses</option>
                          <option>Educators</option>
                          <option>Materials</option>
                        </select>
                      </span>
                      <Link
                        className="btn btn-primary sub-btn"
                        type="submit"
                        onClick={() => props.setSearchPage(1)}
                      >
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Link>
                    </div>
                  </div>
                </form>
              </div> */}
              <div className="trust-user">
                <p>
                  With over 15+ Student Mentors from <span className="font-bold">IIT Kharagpur</span>
                </p>
                {/* <div className="trust-rating d-flex align-items-center">
                  <div className="rate-head">
                    <h2>
                      <div className="number">
                        <CountUp duration={3} className="counter" end={1000} />+
                      </div>
                    </h2>
                  </div>
                  <div
                    className="rating d-flex align-items-center"
                    style={{
                      gap: "8px",
                      color: "gold",
                      "font-size": "1.2rem",
                    }}
                  >
                    <h2 className="d-inline-block average-rating">5.0 </h2>
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                  </div>
                </div> */}
              </div>
              <div className="p-2 px-3 my-5 bg-blue-500 w-fit rounded-full transform transition-all duration-75 hover:scale-105">
                <a style={{fontSize: 20}} className="text-white font-bold" href="/#ProblemOffering">
                  Know more &#11167;
                </a>
              </div>
            </div>
          </div>
          <div
            className={`md:w-[50%] d-flex align-items-center`}
            style={{
              background: "url(/LandingPage/assets/img/blob.svg)",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              className="girl-slide-img aos md:animate-bounce-slow"
              data-aos="fade-up"
            >
              <img
                className="rotate-[30deg] md:block"
                src="/LandingPage/assets/img/rocket_hero.png"
                alt="hero"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
