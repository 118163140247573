import React from "react";
import Navbar from "../components/LandingPage/Navbar";
// import "font-awesome/css/font-awesome.min.css";
import Footer from "../components/LandingPage/Footer";
import ContentBlog from "../components/ProblemBlog/ContentBlog";
import blogImage from "../../src/assets/ProblemBlog/blog.png"

const ProblemBlog = () => {
  return (
    <div className="" id="landingpage">
      <Navbar />
      <div className="container-blog md:w-[50%] w-[90%] mx-auto bg-white mt-36 mb-12 p-8 rounded-lg shadow-2xl text-left">
        <h3 className="text-4xl font-semibold mb-8 text-left">
          Breaking the Barrier: Expanding Career Awareness for PCM Students in
          India
        </h3>
        <img className="w-full rounded-2xl pb-3" src={blogImage} alt="" />
        <div>
          <p className="text-black mb-6 text-lg text-left">
            India’s education system has long focused on conventional career
            paths for students pursuing Physics, Chemistry, and Math (PCM). With
            engineering, medicine, and law standing as the go-to options for
            generations, a startling 93% of PCM students remain unaware of the
            vast number of career choices available to them. While there are
            over 800 potential career paths spanning diverse industries,
            students are often only exposed to a handful of options—leaving many
            uncertain about their future.
          </p>
          <h3 className="text-3xl font-semibold text-left mb-8" id="">
            The Over-Reliance on Conventional Careers
          </h3>
          <p className="text-black mb-6 text-lg text-left">
            The dominance of traditional career paths can be attributed to
            several factors: social expectations, familial pressure, and the
            lack of comprehensive career counselling in schools. In India,
            engineering and medicine are seen as the most “secure” professions,
            ensuring financial stability and societal respect. This has led to
            80% of PCM students choosing engineering, particularly in fields
            like Computer Science and IT. <br />
            <br />
            Medical careers also hold high appeal, with 10-15% of students
            opting for fields like MBBS and Dental. This leaves less than 5% of
            PCM students exploring unconventional career paths such as design,
            finance, or emerging technology fields. The result? A vast pool of
            talent gets funnelled into limited industries, often leading to
            dissatisfaction, unemployment, or underemployment as the job market
            becomes increasingly saturated.
          </p>
          <h3 className="text-3xl font-semibold text-left mb-8" id="">
            Why Career Awareness Matters
          </h3>
          <p className="text-black mb-6 text-lg text-left">
            The consequences of this lack of career awareness are significant.
            Without proper guidance and exposure, students are often unaware of
            fast-growing industries like artificial intelligence (AI), data
            science, digital marketing, and fintech, where demand for skilled
            professionals has grown by over 300% in the last five years. Yet,
            these sectors remain largely untapped by PCM students. <br />
            <br />
            Moreover, as technology continues to evolve, interdisciplinary
            fields like biotechnology, environmental science, and robotics offer
            promising futures for students who are interested in STEM careers
            but might not want to pursue traditional paths. With more than 50%
            of engineering graduates in India either unemployed or working in
            fields unrelated to their degree, it’s clear that broadening career
            awareness is not just necessary—it’s urgent.
          </p>
          <h3 className="text-3xl font-semibold text-left mb-8" id="">
            The Role of Career Counseling and Resources
          </h3>
          <p className="text-black mb-6 text-lg text-left">
            One of the main reasons behind this narrow career focus is the lack
            of professional career counselling in schools. Currently, only 1 in
            10 schools in India offer dedicated career guidance services,
            leaving the majority of students to rely on parents, peers, and
            internet searches for advice. Unfortunately, this often perpetuates
            outdated ideas of career success, restricting students to a small
            set of choices. <br />
            <br />
            Professional career counselling, coupled with psychometric
            assessments and personalised guidance, can open up a world of
            possibilities for students. Tools that incorporate a student's
            preferences, strengths, academic performance, and emerging industry
            trends provide a data-driven approach to career decision-making,
            ensuring students are matched with paths that align with both their
            skills and long-term aspirations.
          </p>
          <h3 className="text-3xl font-semibold text-left mb-8" id="">
            Looking to the Future 
          </h3>
          <p className="text-black mb-6 text-lg text-left">
            The future is full of opportunities for PCM
            students—if they are given the right tools to discover them. By
            integrating career counselling, industry insights, and personalised
            roadmaps, we can bridge the gap between student potential and the
            wealth of career opportunities that await them. Explore your
            potential beyond the conventional. Discover the career path that
            aligns with your passions and unlocks your future.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProblemBlog;
