import { useEffect } from "react";
import ambassador from "../../../assets/LandingPage/assets/img/user/user.jpg";
import images from "./imageLoader";
import companies from "./imageLoaderCompanies";

export default function Ambassador({ profile, name }) {
  const profileData = profile;
  useEffect(() => {
    // console.log(profile)
    console.log(images);
  }, []);
  return (
    <div className="m-auto my-10 min-h-[500px] w-[100%] md:w-[80%] grid grid-cols-1 md:grid-cols-2 rounded-3xl overflow-hidden border-1 border-black bg-white">
      <div className="bg-[#d0e2ff] p-6 md:p-8 flex justify-center items-center">
        <p className="text-md md:text-lg font-bold mb-4">
          {profileData.Description}
        </p>
      </div>
      <div className="bg-background p-6 md:p-8 flex flex-col items-center justify-center space-y-4">
        <div className="w-32 h-32">
          <img className="rounded-full" src={images[name]} alt="" />
        </div>
        <div className="text-center">
          <h3 className="text-xl font-bold">{name}</h3>
          <p className="text-md font-bold">{profileData.Education}</p>
          {/* {profileData?.Company} */}
          <div className="flex justify-center items-center min-h-[100px]">
            <img className="w-16 p-2 pr-1 m-auto" src={companies[profileData?.Companylogo[0]]} alt="" />
            {(companies[profileData?.Companylogo[1]])?<img className="w-32 p-2 pl-1 m-auto" src={companies[profileData?.Companylogo[1]]} alt="" />:""}
            {(companies[profileData?.Companylogo[2]])?<img className="w-32 pl-2 m-auto" src={companies[profileData?.Companylogo[2]]} alt="" />:""}
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <a
            href={profileData.Social?.Linkedin}
            target="_blank"
            className="text-muted-foreground hover:text-primary"
            prefetch={false}
          >
            <LinkedinIcon className="w-5 h-5" />
          </a>
          {profileData.Social?.Instagram ? (
            <a
              href={profileData.Social?.Instagram}
              target="_blank"
              className="text-muted-foreground hover:text-primary"
              prefetch={false}
            >
              <InstagramIcon className="w-5 h-5" />
            </a>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

// function FacebookIcon(props) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
//     </svg>
//   )
// }

function InstagramIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
      <line x1="17.5" x2="17.51" y1="6.5" y2="6.5" />
    </svg>
  );
}

function LinkedinIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
      <rect width="4" height="12" x="2" y="9" />
      <circle cx="4" cy="4" r="2" />
    </svg>
  );
}
