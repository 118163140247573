import React from "react";
import Navbar from "../components/LandingPage/Navbar";
import "font-awesome/css/font-awesome.min.css";
import Footer from "../components/LandingPage/Footer";
import ContentsPP from "../components/TermsAndConditions/ContentsPP";

const Privacy = () => {
  return (
    <>
      <div className="main-wrapper" id="landingpage">
        <Navbar></Navbar>
        <div className="container-pp md:w-[50%] w-[90%] mx-auto bg-white mt-36 mb-12 p-8 rounded-lg shadow-2xl text-justify">
          <h3 className="text-4xl font-semibold text-center mb-8">Privacy Policy</h3>

          <p className="text-black mb-6">
            We value your trust in us and are aware of how important secure
            transactions and information privacy are. Despite the fact that you
            might be able to browse some portions of the platform without
            registering with us, by accessing this platform, providing your
            information, or using any service made available on the platform,
            you hereby expressly accept and agree to be bound by the terms and
            conditions of this Privacy Policy, the Terms of Use, and the
            applicable service terms and conditions. Additionally, you hereby
            expressly consent to the application of the laws of India, including
            but not limited to those pertaining to privacy and data protection.
            If you disagree, kindly avoid using or gaining access to our
            Platform.
          </p>
          <ContentsPP />
          <h3 className="text-2xl font-semibold mt-6 mb-4" id="information-collection">Information Collection</h3>
          <p className="text-black mb-6">
            When you use our platform or services, or engage with us in other
            ways, we may periodically ask for additional information related to
            the data we have already gathered about you as part of our ongoing
            relationship. Name, date of birth, address, phone number, email
            address, occupation, and any other information shared as
            identification or address proof are just a few examples of the data
            we may collect from users who sign up, register, or use our
            platforms. Furthermore, it might include private information about
            you, such as your password, or details of your bank account, credit
            card, debit card, or another payment method (so that you can use
            specific Platform features to help you with your shopping
            experience). Periodically, you might give us information about your
            preferences, browsing history, voicemails, call history, purchasing
            patterns, and other things. Our main goal is to provide you with a
            safe, efficient, seamless, and customized experience. This allows us
            to better understand your needs and tailor our Platform to meet
            them, while also ensuring that you have a safer and easier time
            using it. Generally, you are free to browse the Platform without
            revealing any personal information about yourself and in an
            anonymous manner. Once you provide us with your personal
            information, you are no longer anonymous to us. Which fields are
            required and which ones are optional are as clear as possible. You
            may choose not to provide information at any time by choosing not to
            use a particular service or feature on the Platform. We may monitor
            the preferences, purchasing patterns, and other information you
            choose to share on our platform. We use this information to gather
            information about the demographics, interests, and behavior of our
            users so that we can better understand and serve them. This
            information is gathered and assessed as a whole. Your IP address,
            the URL you just left (whether or not it was on our Platform), the
            URL you visit next (whether or not it was on our Platform), details
            about your web browser, and the URL you just came from may all be
            included in this data. If you sign up for our loyalty program or
            take part in a third-party loyalty program that we provide, we will
            collect and store your personal information, such as your name,
            contact information, email address, communication address, date of
            birth, gender, zip code, lifestyle details, demographic information,
            and employment information. You or a third-party business partner
            that operates platforms where you can accrue loyalty points for
            purchasing services has provided us with this information. We’ll
            also keep track of the information you exchange with the Platform
            and other platforms used by our business partners. When a
            third-party business partner obtains your personal information
            directly from you, you will be subject to their privacy policies.
            Because we cannot be held responsible for their privacy practices or
            the information contained in them, we ask that you read their
            privacy policies before providing any information. If you create an
            account with us or do business with us, we may need additional
            information from you, such as your billing address, credit card
            number, expiration date and other payment instrument information, in
            order to provide services, enable transactions, or refund canceled
            transactions. If you choose to post messages on our message boards,
            private messages, pictures, the gift card message box, chat rooms,
            or other message areas, or if you use voice commands to shop on the
            Platform, we may collect the information you provide. When posting
            such messages, private information, images, and reviews, please
            exercise caution because they will be accessible to the public and
            might be read by others as well. We retain this information for as
            long as necessary to resolve disputes, provide customer support,
            conduct internal research, and troubleshoot problems, in accordance
            with the law. If you send us personal letters or emails, or if other
            users or third parties contact us about your use of the Platform or
            your postings there, we may compile information about you into a
            file. While you can browse some areas of our Platform without
            registering, some activities (such as placing an order, using our
            online content or services, or participating in any events) do
            require registration. You might receive offers based on your
            preferences, past purchases, or interests if your contact
            information is used. If you ever receive an email or a call claiming
            to be from MatriGyan, please do not give out any sensitive personal
            information, such as your debit/credit card PIN, net-banking
            password, or mobile banking password. You won’t ever receive a
            request for such information from MatriGyan or our associated
            partners. If you’ve already disclosed such information, you should
            immediately inform the appropriate law enforcement agency.
          </p>
          <h3 className="text-2xl font-semibold mt-6 mb-4" id="use">Use</h3>
          <p className="text-black mb-6">
            We use personal information to carry out your service request. If we
            decide to use your personal information for marketing, you will have
            the choice to object. We use your personal information to support
            safe service practices, help sellers and business partners process
            and complete orders, enhance customer experience, resolve disputes,
            troubleshoot issues, collect money, determine consumer interest in
            our services, inform you about offers and updates both online and
            offline, accommodate your preferences, find and prevent error,
            fraud, and other illegal activity, and uphold our terms. In order to
            send you order-related marketing correspondence, we will ask for
            your permission to access your text messages (SMS), contacts in your
            address book, camera, photo gallery, location, and device
            information. You are aware that denying us permission could prevent
            you from using these services. As part of our ongoing efforts to
            improve our service offerings, we collect and analyze demographic
            and profile information about users’ activity on our Platform. We
            recognize you and use your IP address to administer our Platform and
            spot problems with our server. Your IP address is also used to
            locate you and gather broad demographic information about you. On
            occasion, we might ask you to take part in surveys that are
            conducted by us or a different market research company. Your name,
            contact information, gender, date of birth, demographic information
            (such as your zip code, age, or income level), and other details
            about your interests, home or lifestyle, purchasing patterns, or
            previous preferences could all be requested in these surveys. Data
            collection for the survey may include recording audio or video. You
            must voluntarily participate in these surveys if you wish to. We use
            this information to tailor your experience on our platform by
            showing you content we think you’ll find interesting and displaying
            content based on your preferences.
          </p>
          <h3 className="text-2xl font-semibold mt-6 mb-4" id="cookies">Cookies</h3>
          <p className="text-black mb-6">
            On some pages of the Platform, we use data collection tools like
            “cookies” to track promotional effectiveness, analyze the flow of
            our web pages and advance trust and safety. Cookies are small files
            that are downloaded to your computer’s hard drive and help us
            provide our services. Your personal information is not stored in
            cookies. We provide some features that can only be accessed by using
            a “cookie.” Additionally, we use cookies so that you have to enter
            your password less frequently while logged in. Additionally, cookies
            can assist us in delivering content that is customized to your
            interests. The majority of cookies are “session cookies,” which
            means that they are removed from your hard drive at the conclusion
            of a session. If your browser allows, you are always free to refuse
            or delete our cookies, though doing so may prevent you from using
            some platform features and force you to enter your password more
            frequently while logging in. Additionally, third-party “cookies” or
            other similar devices may be inserted on some pages of the platform.
            We have no control over how third parties use cookies. For marketing
            and analytical purposes, we use third-party partner cookies like
            Google Analytics, which helps us better understand how visitors use
            the site.
          </p>
          <h3 className="text-2xl font-semibold mt-6 mb-4" id="sharing">Sharing</h3>
          <p className="text-black mb-6">
            We may share your personal information with our Group entities, our
            other corporate entities, and our affiliates to enable you to access
            the services they provide. These businesses and their affiliates may
            market to you as a result of such sharing unless you specifically
            opt-out. We might provide personal information to outside parties.
            To provide you with access to the services we provide, to meet our
            legal obligations, to enforce our user agreement, to conduct our
            marketing and advertising campaigns, and to prevent, detect,
            mitigate, and investigate fraudulent or illegal activities involving
            our services, it might be necessary for us to make this disclosure.
            We reserve the right to disclose personal information, including
            sensitive information, to government entities or other recognized
            law enforcement organizations if required to do so by law or if we
            have a good-faith belief that doing so is reasonably necessary to
            comply with subpoenas, court orders, or other legal processes. In
            the sincere belief that such disclosure is indubitably required to –
            We may release personal data to law enforcement agencies,
            third-party rights holders, or others in order to: enforce our Terms
            or Privacy Policy; address claims that any advertisement, posting,
            or other content violates the rights of a third party; or protect
            the rights, property, or personal safety of our users or the public.
            We and our affiliates may share or sell some or all of your personal
            information to that other business entity in the event that we (or
            our assets) choose to merge with, be acquired by, reorganize,
            amalgamate, or otherwise restructure our business. If such a
            transaction occurs, the other business (or the new combined entity)
            will be required to abide by this privacy policy with respect to
            your personal data.
          </p>
          <h3 className="text-2xl font-semibold mt-6 mb-4" id="links-to-other-sites">Links to Other Sites</h3>
          <p className="text-black mb-6">
            Links on our website can take you to other websites that may track
            your personal information. We have no control over the content or
            privacy practices of the websites that are linked to MatriGyan.
          </p>
          <h3 className="text-2xl font-semibold mt-6 mb-4" id="security-precautions">Security Precautions</h3>
          <p className="text-black mb-6">
            To prevent misuse, loss, or unauthorized access to your personal
            information, we implement reasonable security practices and
            procedures. Every time you access your account information, you have
            the choice of doing so through a secure server. Unfortunately, due
            to factors beyond our control, information transmission is not
            completely secure. We use industry-recognized security measures and
            frequently update our systems to guard against hackers and the
            spread of viruses that could compromise your personal information.
            Users of the platform acknowledge the security implications of data
            transmission over the internet and the World Wide Web, despite the
            fact that complete security cannot always be guaranteed. As a
            result, using the platform will always come with some inherent
            risks. Users are responsible for keeping their login and password
            information secure.
          </p>
          <h3 className="text-2xl font-semibold mt-6 mb-4" id="advertisements">Advertisements</h3>
          <p className="text-black mb-6">
            Third-party ad networks deliver advertisements when you visit our
            platform. In order to deliver advertisements for services you might
            be interested in, these businesses may use information about your
            visits to this Platform and other websites but not your name,
            address, email address, or phone number.
          </p>
          <h3 className="text-2xl font-semibold mt-6 mb-4" id="data-retention">Data Retention</h3>
          <p className="text-black mb-6">
            We don’t keep your personal information any longer than is required
            to achieve the goals for which it was collected or as required by
            any relevant laws. Nevertheless, we reserve the right to retain your
            personal information if we are required to do so by law if we must
            adhere to any applicable statutory or regulatory retention
            requirements if we believe it might be necessary to prevent fraud or
            other abuse in the future, if it will aid MatriGyan in the defense
            of legal claims, or if we have other good reasons to do so. We may
            continue to store your information in an anonymous format for
            analytical and research purposes.
          </p>
          <h3 className="text-2xl font-semibold mt-6 mb-4" id="consents">Consent</h3>
          <p className="text-black mb-6">
            By using our Platform or submitting your information, you agree to
            the gathering, use, storage, disclaimer, and other preparation of
            your information (including sensitive personally identifiable
            information) on the Platform in compliance with this Privacy Policy.
            You guarantee that you have their permission before providing us
            with any private details about other individuals, and you give us
            permission to use that information as specified in this privacy
            statement. By providing your personal details through the forum or
            any partner platforms or establishments, you assent to us (such as
            our other giant corporations, affiliates, lending associates,
            technology partners, marketing channels, business associates, and
            other third parties) trying to contact you via SMS, online messaging
            apps, call, and/or email for the purposes described in this Privacy
            Statement.
          </p>
          <h3 className="text-2xl font-semibold mt-6 mb-4" id="changes-to-privacy-policy">Changes to this Privacy Policy</h3>
          <p className="text-black mb-6">
            Please frequently check our Privacy Policy for updates. We may
            update this privacy statement to reflect changes to our information
            practices. We would then notify you of major changes when needed to
            do so by applicable law, either by publishing the date our Privacy
            Notice was last modified or posting a notice on our forum.
          </p>
          <h3 className="text-2xl font-semibold mt-6 mb-4" id="queries">Queries</h3>
          <p className="text-black mb-6">
            If you have any queries, worries, or grievances about how your
            personal information is gathered or used in accordance with this
            privacy statement, kindly contact us using the information provided
            above.
          </p>
        </div>
        <Footer></Footer>
      </div>
    </>
  );
};

export default Privacy;
