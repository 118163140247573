export default function ProbOfferPallete() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 max-w-full">
      <div className="flex items-center justify-center gap-4 bg-card p-4 rounded-lg shadow-xl bg-[#f2f6ff]">
        {/* <div className="bg-muted rounded-md flex items-center justify-center aspect-square w-12">
          <InboxIcon className="w-6 h-6 text-muted-foreground" />
        </div> */}
        <div>
          {" "}
          <p className="text-md font-bold">
            Personalized Psychometric Assessments
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center gap-4 bg-card p-4 rounded-lg shadow-xl bg-[#f2f6ff]">
        {/* <div className="bg-muted rounded-md flex items-center justify-center aspect-square w-12">
          <CalendarIcon className="w-6 h-6 text-muted-foreground" />
        </div> */}
        <div>
          <p className="text-md font-bold">
            Cohorted Career Development Workshops{" "}
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center gap-4 bg-card p-4 rounded-lg shadow-xl bg-[#f2f6ff]">
        {/* <div className="bg-muted rounded-md flex items-center justify-center aspect-square w-12">
          <BriefcaseIcon className="w-6 h-6 text-muted-foreground" />
        </div> */}
        <div>
          <p className="text-md font-bold">
            Strength-Based Career Path Advisory
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center gap-4 bg-card p-4 rounded-lg shadow-xl bg-[#f2f6ff]">
        {/* <div className="bg-muted rounded-md flex items-center justify-center aspect-square w-12">
          <SettingsIcon className="w-6 h-6 text-muted-foreground" />
        </div> */}
        <div>
          <p className="text-md font-bold">Life & Career Alignment Coaching </p>
        </div>
      </div>
    </div>
  );
}

// function BriefcaseIcon(props) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <path d="M16 20V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
//       <rect width="20" height="14" x="2" y="6" rx="2" />
//     </svg>
//   );
// }

// function CalendarIcon(props) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <path d="M8 2v4" />
//       <path d="M16 2v4" />
//       <rect width="18" height="18" x="3" y="4" rx="2" />
//       <path d="M3 10h18" />
//     </svg>
//   );
// }

// function InboxIcon(props) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <polyline points="22 12 16 12 14 15 10 15 8 12 2 12" />
//       <path d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />
//     </svg>
//   );
// }

// function SettingsIcon(props) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" />
//       <circle cx="12" cy="12" r="3" />
//     </svg>
//   );
// }
