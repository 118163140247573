import evaluation from "../../assets/LandingPage/assets/img/psychometric/evaluation.jpg"

export default function Psychometric() {
  return (
    <div className="flex flex-col md:flex-row w-full min-h-[500px] rounded-3xl shadow-2xl max-w-7xl m-auto my-24 justify-center items-center">
      <div className="w-[40%] md:w-1/2 relative">
        <img
          src={evaluation}
          alt="Evaluation Metrics"
        />
      </div>
      <div className="w-fit md:w-1/2 p-8 md:p-12 flex flex-col justify-center">
        <div className="text-3xl md:text-4xl font-bold mb-6 text-gray-800">
          Know yourself with our best metrics
        </div>
        <ul className="space-y-4">
          {[
            "Personality and Emotional Quotient (EQ)",
            "Interests and Aspirations",
            "Skillset Evaluation",
            "Career Preferences and Goals",
            "Aptitude and Intelligence Quotient (IQ)",
          ].map((item, index) => (
            <li key={index} className="flex items-center space-x-4">
              <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center">
                <span className="text-blue-600 font-bold text-lg">20%</span>
              </div>
              <span className="text-md md:text-lg text-gray-700 font-bold">{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}