// src/components/TableOfContents.js

import React from 'react';

const ContentsTC = () => {
  return (
    <div className="top-4 p-4 bg-gray-100 rounded-lg shadow-md mb-8">
      <h2 className="text-xl font-bold mb-4">Table of Contents</h2>
      <ul className="list-disc pl-4 space-y-2">
        <li>
          <a href="#overview" className="font-bold text-blue hover:underline-offset-1">
            Overview
          </a>
        </li>
        <li>
          <a href="#references" className="font-bold text-blue hover:underline-offset-1">
            References of Terms Used
          </a>
        </li>
        <li>
          <a href="#requirements" className="font-bold text-blue hover:underline-offset-1">
            Requirements
          </a>
        </li>
        <li>
          <a href="#registration" className="font-bold text-blue hover:underline-offset-1">
            Obligacy Of Registration
          </a>
        </li>
        <li>
          <a href="#electronic-interaction" className="font-bold text-blue hover:underline-offset-1">
            E-Registration and Electronic Interaction
          </a>
        </li>
        <li>
          <a href="#services" className="font-bold text-blue hover:underline-offset-1">
            Synopsis of Services
          </a>
        </li>
        <li>
          <a href="#payments" className="font-bold text-blue hover:underline-offset-1">
            Payments
          </a>
        </li>
        <li>
          <a href="#taxation" className="font-bold text-blue hover:underline-offset-1">
            Taxation
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ContentsTC;
