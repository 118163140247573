import React, { useEffect, useRef, useState } from "react";
import CompletedCourses from "../../components/StudentDashboard/CompletedCourses";
import Sidebar from "../../components/StudentDashboard/Sidebar";
import CurrentCourses from "../../components/StudentDashboard/CurrentCourses";
import Welcome from "../../components/StudentDashboard/Welcome";
import Statistics1 from "../../components/StudentDashboard/Statistics1";
import Statistics2 from "../../components/StudentDashboard/Statistics2";
import Tests from "../../components/StudentDashboard/Tests";
import LiveLectures from "../../components/StudentDashboard/LiveLectures";
import ItemCard from "../../components/StudentDashboard/ItemCard";
import { Checkbox } from "@mantine/core";
import DashboardCalendar from "../../components/StudentDashboard/DashboardCalendar";
import Tasklist from "../../components/StudentDashboard/Tasklist";
import axios from "axios";
import { getCookie } from "../../utils/apiCaller";
import Loader from "../../loader";
import { useNavigate } from "react-router-dom";
import { MagicCardDemo } from "../../components/PricingCard/Card";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getUser from "../../utils/checkUser";

import DashboardButtonPallate from "../../components/StudentDashBoardTest/Dashboardbuttons";
import Progressbar from "../../components/StudentDashBoardTest/Progressbar";
import userdash from "../../assets/StudentDashboard/images/logos/userdash.svg";

const StudentDashboard = (props) => {
  const navigate = useNavigate();
  const [Data, setData] = useState({});
  const mounted = useRef(false);
  const [loader, setLoader] = useState(true);
  /*
  const [courses,setCourses] = useState([]);
  const getCourses = async ()=>{
    try{
      const res = await axios.get('http://127.0.0.1:8000/get-courses/')
      const data = res.data
      console.log(res.data);
      setCourses(data.data);
    } catch(error){
      console.log(error);
    }
  }
  useEffect(()=>{
    getCourses();
  }, [])
*/
  const profilePic =
    props.user?.current?.code === 1
      ? `${process.env.REACT_APP_BACKEND_URL}${props.user?.current?.student?.profile_pic}`
      : props.user?.current?.code === 2
      ? `${process.env.REACT_APP_BACKEND_URL}${props.user?.current?.educator?.profile_pic}`
      : null;
  useEffect(() => {
    setLoader(false);
    console.log(props);
  }, []);
  useEffect(() => {
    mounted.current = true;
    const config = {
      withCredentials: true,
      // headers: {
      //   "X-CSRFToken": getCookie("csrftoken"),
      // },
    };
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/student-dashboard-data`,
        config
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.success) {
          if (res.data.code === 2) navigate("/educator");
          else {
            toast("Please Login first.");
            navigate("/login");
          }
        }

        if (mounted.current) {
          setData(res.data);
          console.log(Data);
        }
      })
      .catch((err) => {
        const notify = () => toast(err.message);
        notify();
        navigate("/");
      });
    return () => (mounted.current = false);
  }, []);

  var comp = 0;
  if (Object.keys(Data).length)
    comp = Data.enrolled_courses.length - Data.on_courses.length;

  return (
    <>
      <ToastContainer></ToastContainer>
      {loader ? <Loader></Loader> : ""}
      <div className="min-h-100vh flex grow bg-slate-50 dark:bg-navy-900 tw-dash-page">
        <Sidebar user={props.user} tab={0} />
        <div className="flex lg:w-fit md:w-6xl sm:w-4xl m-auto min-h-screen lg:flex-col bg-background">
          <main className="flex flex-col items-left justify-left gap-8 px-4 py-12 sm:px-6 md:flex-col md:gap-12 lg:px-8 md:ml-20">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col gap-4 md:max-w-[550px]">
                <h1 className="lg:text-7xl font-bold tracking-tighter sm:text-3xl md:text-5xl">
                  Welcome to your dashboard, {Data.name}
                </h1>
                <p className="text-muted-foreground md:text-xl sm:text-lg">
                  Unlock your career potential with our cutting-edge dashboard.
                  Explore numerous modern career paths, receive personalised
                  recommendations based on your strengths, and be prepared to
                  make informed decisions most optimum for you. Take control of
                  your future with data-driven insights tailored just for you!
                </p>
                {!props.user?.current?.student?.payment_done && (
                  <button
                    className="text-white bg-gradient-to-br from-purple-600 to-blue-500
                  hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300
                  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    onClick={props.doCheckout}
                  >
                    Buy Now
                  </button>
                )}
              </div>
              <div className="">
                <img
                  src={profilePic}
                  className="hidden lg:block md:block sm:block lg:w-[450px] lg:h-[450px] md:w-[200px] md:h-[200px]"
                  alt="user"
                />
              </div>
            </div>
            <Progressbar user={props.user} />
            {!props.user?.current?.student?.payment_done && (
              <MagicCardDemo doCheckout={props.doCheckout} />
            )}
            <DashboardButtonPallate />
          </main>
        </div>
      </div>
    </>
  );
};

export default StudentDashboard;
