import React, { useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Ambassador from "./Ambassador";
import data from "./ambassadors.json";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';
import { Autoplay } from "swiper/modules";

export default function AmbassadorCarousel() {
  return (
    <div className="my-5 max-w-[100%] md:max-w-[80%] m-auto">
      <div className="mt-16 mb-5 text-3xl md:text-5xl font-bold text-center">
        Meet Our Student Ambassadors
      </div>
      <Swiper
        loop={true}
        grabCursor={false}
        // effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[Autoplay, Navigation]}
        navigation={true}
        // pagination={{clickable:true}}
        noSwiping={true}
        className="mySwiper"
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
      >
        {Object.keys(data).map((name) => (
          <SwiperSlide><Ambassador key={name} name={name} profile={data[name]} /></SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
