import React, { useState } from "react";
import { useTheme } from "next-themes";
import { MagicCard } from "../../magicComp/components/magicui/magic-card";
import { PiStudentFill } from "react-icons/pi";
import Modal from "react-modal";
import RazorpayPage from "./RazorpayPage"; // Import the RazorpayPage component
import { BorderBeam } from "../../magicComp/components/magicui/border-beam";

// Required for accessibility, attach the modal to the root app element
Modal.setAppElement("#root");

export function MagicCardDemo(props) {
  const { theme } = useTheme();

  return (
    <div>
      {/* Main MagicCard Section */}
      <div
        className={
          "flex h-[500px] w-full flex-col lg:h-[350px] lg:flex-row justify-center items-center gap-8"
        }
        id="BuyNow"
      >
        <MagicCard
          className="flex flex-col items-center justify-center shadow-lg text-center rounded-lg border border-gray-200 bg-[#e9edf5] w-[80%] lg:w-[40%] h-[350px] mb-5 p-6 relative"
          gradientColor={theme === "dark" ? "#262626" : "#D9D9D955"}
        >
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-200 px-4 py-1 rounded-full text-sm font-semibold text-blue-800">
            Current Cohort
          </div>
          <div className="mb-4">
            <PiStudentFill className="text-4xl text-blue-500 " />
          </div>
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Complete program</h2>
          <p className="text-4xl font-bold ">767 INR</p>
          <button
            onClick={props.doCheckout}
            className="mt-6 bg-blue-500 text-white px-10 py-4 text-lg rounded-full hover:bg-green-400 transition"
          >
            Buy Now
          </button>
        </MagicCard>

        <MagicCard
          className="flex flex-col items-center justify-center shadow-lg text-center rounded-lg border border-gray-200 bg-[#e9edf5] w-[80%] lg:w-[20%] h-[350px] mb-5 p-6"
          gradientColor={theme === "dark" ? "#262626" : "#D9D9D955"}
        >
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Incoming Cohorts</h2>
          <p className="text-4xl font-bold">Interested</p>
          <button className="mt-6 bg-blue-500 text-white px-10 py-4 text-lg rounded-full hover:bg-green-400 transition">
            Request Invite
          </button>
        </MagicCard>
      </div>
    </div>
  );
}
