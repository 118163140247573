import React from "react";
import useIntersectionObserver from "./useIntersectionObserver"; // Import the custom hook

const Motto = () => {
  const [ref, isVisible] = useIntersectionObserver({
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  return (
    <div className="flex justify-center items-center max-w-fit m-auto p-6 h-40 backdrop-blur-md shadow-lg rounded-3xl">
      <h3
        ref={ref}
        className={`text-2xl md:text-4xl font-bold text-blue-500 text-center tracking-wider transition-opacity duration-700 ease-in-out transform ${
          isVisible
            ? "md:opacity-100 md:translate-y-0"
            : "md:opacity-0 md:translate-y-0"
        }`}
      >
        &quot;Bridging the Gap Between Academic Success and Career Clarity&quot;
      </h3>
    </div>
  );
};

export default Motto;
